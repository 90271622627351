@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
@import "variables";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Lexend", serif;
  font-optical-sizing: auto;
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: $color-30-light;
  gap: 8px;

  a {
    background: $map-black;
    color: $color-30;
    padding: 16px 24px;
    border-radius: 16px;
    text-decoration: none;
    transition: all 0.3s ease-out;

    &:hover {
      background: $color-30;
      color: $map-black;
    }
  }
}

button {
  outline: none !important;
  border: none !important;
}

.play-with-svg-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    max-height: 60vh;
    overflow: visible;
  }
}

@import "Lara";
@import "Audiospectrum";
@import "Auth";
