@import "variables";

#AudioInput {
  display: block;
}

.music-spectrum-container {
  .controls {
    position: absolute;
    left: 0;
    display: flex !important;
    flex-direction: column !important;
    gap: 16px !important;
    align-items: center;
    input {
      background: #151515;
      display: block !important;
    }
  }
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // background: $map-black;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  gap: 32px;
  position: absolute;
  fill: $map-black;

  button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    pointer-events: none;

    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    color: $color-30;
  }

  svg {
    cursor: pointer;
    max-height: 60vh !important;
    max-width: 68% !important;
    width: 100% !important;
    height: 100% !important;
    fill: inherit;
    position: absolute;

    transition: all 0.6s cubic-bezier(0.25, 0, 0, 1);
    opacity: 1;

    path {
      fill: $color-spectrum;
      opacity: 0.12;
      transform-origin: center;

      &:nth-child(1) {
        transform: rotate(0deg);
      }
      &:nth-child(2) {
        transform: rotate(62deg);
      }
      &:nth-child(3) {
        transform: rotate(124deg);
      }
      &:nth-child(4) {
        transform: rotate(186deg);
      }
      &:nth-child(5) {
        transform: rotate(248deg);
      }
    }
    #CenterPath,
    #CenterPath2 {
      opacity: 1 !important;
      fill: inherit;

      stroke: $color-spectrum-light;
      stroke-width: 0.4px;
      transition: stroke 0.6s cubic-bezier(0.25, 0, 0, 1);
    }
    // #CenterPath {
    //   // box-shadow: 0px 0px 5px 1px $color-spectrum-light inset;
    //   filter: drop-shadow(0px 0px 12px rgba($color-spectrum-light, 0.32));
    // }
    #CenterPath2 {
      stroke-width: 4px;
      fill: none !important;
      filter: blur(12px);
    }
    &:hover {
      fill: $color-10;

      #CenterPath,
      #CenterPath2 {
        stroke: $color-10;
      }
    }
  }

  .controls {
    display: flex;
    gap: 16px;
    align-items: center;

    input[type="file"] {
      background: $map-black;
      color: $color-30;
      padding: 8px 16px;
      border-radius: 8px;
      cursor: pointer;

      &::-webkit-file-upload-button {
        background: $color-30;
        color: $map-black;
        padding: 8px 16px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        margin-right: 16px;
        transition: all 0.3s ease-out;

        &:hover {
          background: $map-black;
          color: $color-30;
        }
      }
    }

    button {
      background: $map-black;
      color: $color-30;
      padding: 8px 24px;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease-out;

      &:hover {
        background: $color-30;
        color: $map-black;
      }
    }
  }

  svg {
    max-height: 60vh;
    overflow: visible;
    color: $map-black;
  }
}
#ContainerSpectrumOnly {
  background-color: $main-bg;
}
#PlayButtonSpectrumOnly {
  position: relative;
  cursor: pointer;
  pointer-events: all;
}
