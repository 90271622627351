@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Lexend", serif;
  font-optical-sizing: auto;
}

.home-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100vh;
  background: #e9e7e7;
  gap: 8px;
}

.home-container a {
  background: #151515;
  color: #ffffff;
  padding: 16px 24px;
  border-radius: 16px;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.home-container a:hover {
  background: #ffffff;
  color: #151515;
}

button {
  outline: none !important;
  border: none !important;
}

.play-with-svg-container {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.play-with-svg-container svg {
  max-height: 60vh;
  overflow: visible;
}

.app-container {
  width: 100vw;
  height: 100vh;
  background: #151515;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}

.header {
  padding: 16px 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: none !important;
}

.header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
}

.header-left h1 {
  color: #ffffff;
  font-size: 24px;
  font-family: "Lexend", sans-serif;
  font-weight: 500;
}

.header-left .status-dot {
  width: 13.33px;
  height: 13.33px;
  background: #e72166;
}

.header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4px;
}

.header-right button {
  cursor: pointer;
  background: transparent;
  padding: 8px 12px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 12px;
}

.header-right button:hover {
  background: rgba(0, 0, 0, 0.62);
}

.two-column-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
  padding: 0 24px 24px 24px;
  height: calc(100vh - 90px);
}

.two-column-layout .left-column {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: #1c1d20;
  border-radius: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-left: 1px solid rgba(255, 255, 255, 0.08);
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  overflow: hidden;
}

.two-column-layout .right-column {
  min-width: 24vw;
  max-width: 480px;
  background: #1c1d20;
  border-radius: 24px;
  height: 100%;
  padding: 32px;
  border-left: 1px solid rgba(255, 255, 255, 0.08);
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  position: relative;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.two-column-layout .right-column h2 {
  color: #ffffff;
  font-size: 24px;
  font-family: "Lexend", sans-serif;
  font-weight: 500;
}

.messages-container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 24px 16px;
  padding-bottom: 8px;
  margin-bottom: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  gap: 16px;
  overflow-y: scroll;
  background: inherit;
  -webkit-transition: all 0.66s cubic-bezier(0.25, 0, 0, 1);
  transition: all 0.66s cubic-bezier(0.25, 0, 0, 1);
}

.messages-container::-webkit-scrollbar {
  display: none;
}

.ContentAwaiting {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: rgba(0, 0, 0, 0.62);
}

.message {
  padding: 12px;
  border-radius: 16px;
  max-width: 80%;
}

.message.user {
  -ms-flex-item-align: end;
      align-self: flex-end;
  background: #323441;
  color: #ffffff;
  text-align: right;
}

.message.assistant {
  -ms-flex-item-align: start;
      align-self: flex-start;
  text-align: left;
  background: #e72166;
  color: #ffffff;
}

.message.assistant strong {
  font-weight: 700;
}

.message.assistant i {
  font-style: italic;
  font-weight: 300;
}

.message.ContentAwaiting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: rgba(0, 0, 0, 0.62);
  min-height: 48px;
  padding: 8px;
}

.input-area {
  position: -webkit-sticky;
  position: sticky;
  bottom: 24px;
  padding: 0 32px;
  background: #1c1d20;
  z-index: 10;
}

.input-area .input-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  background: #323441;
  border-radius: 12px;
  padding: 8px;
  border-left: 1px solid rgba(255, 255, 255, 0.08);
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.input-area .text-input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: rgba(0, 0, 0, 0.38);
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  height: 48px;
}

.input-area .text-input .message-icon {
  margin-left: 12px;
  width: 24px;
  height: 24px;
}

.input-area .text-input input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: transparent;
  border: none !important;
  outline: none;
  color: #ffffff;
  margin-right: 12px;
  height: 100%;
}

.input-area .text-input input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.input-area .text-input input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.input-area .text-input input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.input-area .text-input input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.input-area .media-button {
  padding: 12px 24px;
  background: rgba(0, 0, 0, 0.38);
  border-radius: 8px;
}

.input-area .send-button {
  padding: 12px 32px;
  background: #e72166;
  border-radius: 24px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.input-area .send-button:hover {
  background: #9e0e40;
}

.input-area .send-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.transcript {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
}

@media screen and (max-width: 1080px) {
  .MbileLayout .header {
    padding: 8px 24px;
  }
  .MbileLayout .two-column-layout {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    gap: 0;
  }
  .MbileLayout .two-column-layout .right-column {
    max-width: 100% !important;
    width: 100% !important;
  }
  .MbileLayout .two-column-layout .left-column {
    height: 0 !important;
    border-color: transparent !important;
    background: transparent !important;
  }
}

#AudioInput {
  display: block;
}

.music-spectrum-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  gap: 32px;
  position: absolute;
  fill: #151515;
}

.music-spectrum-container .controls {
  position: absolute;
  left: 0;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
  gap: 16px !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.music-spectrum-container .controls input {
  background: #151515;
  display: block !important;
}

.music-spectrum-container button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: transparent;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  pointer-events: none;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
}

.music-spectrum-container svg {
  cursor: pointer;
  max-height: 60vh !important;
  max-width: 68% !important;
  width: 100% !important;
  height: 100% !important;
  fill: inherit;
  position: absolute;
  -webkit-transition: all 0.6s cubic-bezier(0.25, 0, 0, 1);
  transition: all 0.6s cubic-bezier(0.25, 0, 0, 1);
  opacity: 1;
}

.music-spectrum-container svg path {
  fill: #70cfff;
  opacity: 0.12;
  -webkit-transform-origin: center;
          transform-origin: center;
}

.music-spectrum-container svg path:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.music-spectrum-container svg path:nth-child(2) {
  -webkit-transform: rotate(62deg);
          transform: rotate(62deg);
}

.music-spectrum-container svg path:nth-child(3) {
  -webkit-transform: rotate(124deg);
          transform: rotate(124deg);
}

.music-spectrum-container svg path:nth-child(4) {
  -webkit-transform: rotate(186deg);
          transform: rotate(186deg);
}

.music-spectrum-container svg path:nth-child(5) {
  -webkit-transform: rotate(248deg);
          transform: rotate(248deg);
}

.music-spectrum-container svg #CenterPath,
.music-spectrum-container svg #CenterPath2 {
  opacity: 1 !important;
  fill: inherit;
  stroke: #3ef9ff;
  stroke-width: 0.4px;
  -webkit-transition: stroke 0.6s cubic-bezier(0.25, 0, 0, 1);
  transition: stroke 0.6s cubic-bezier(0.25, 0, 0, 1);
}

.music-spectrum-container svg #CenterPath2 {
  stroke-width: 4px;
  fill: none !important;
  -webkit-filter: blur(12px);
          filter: blur(12px);
}

.music-spectrum-container svg:hover {
  fill: #e72166;
}

.music-spectrum-container svg:hover #CenterPath,
.music-spectrum-container svg:hover #CenterPath2 {
  stroke: #e72166;
}

.music-spectrum-container .controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.music-spectrum-container .controls input[type="file"] {
  background: #151515;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.music-spectrum-container .controls input[type="file"]::-webkit-file-upload-button {
  background: #ffffff;
  color: #151515;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 16px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.music-spectrum-container .controls input[type="file"]::-webkit-file-upload-button:hover {
  background: #151515;
  color: #ffffff;
}

.music-spectrum-container .controls button {
  background: #151515;
  color: #ffffff;
  padding: 8px 24px;
  border-radius: 8px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.music-spectrum-container .controls button:hover {
  background: #ffffff;
  color: #151515;
}

.music-spectrum-container svg {
  max-height: 60vh;
  overflow: visible;
  color: #151515;
}

#ContainerSpectrumOnly {
  background-color: #1c1d20;
}

#PlayButtonSpectrumOnly {
  position: relative;
  cursor: pointer;
  pointer-events: all;
}
