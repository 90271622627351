// Color variables
$color-60: #0c1136;
$color-10: #e72166;
$color-30: #ffffff;
$main-bg: #1c1d20;
$map-black: #151515;
$color-60-alt: #323441;
$color-60-blue: #7081ff;
$color-10-dark: #9e0e40;
$color-10-darker: #380114;
$color-30-light: #e9e7e7;
$color-60-dark: #1b1c27;
$color-60-black: rgba(0, 0, 0, 0.38);
$color-60-black-alt: rgba(0, 0, 0, 0.62);

$color-spectrum: #70cfff;
$color-spectrum-light: #3ef9ff;
