@import "variables";

.app-container {
  width: 100vw;
  height: 100vh;
  background: $map-black;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  padding: 16px 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none !important;

  &-left {
    display: flex;
    align-items: center;
    gap: 12px;

    h1 {
      color: $color-30;
      font-size: 24px;
      font-family: "Lexend", sans-serif;
      font-weight: 500;
    }

    .status-dot {
      width: 13.33px;
      height: 13.33px;
      background: $color-10;
    }
  }

  &-right {
    display: flex;
    gap: 4px;

    button {
      cursor: pointer;
      background: transparent;
      padding: 8px 12px;
      transition: all 0.3s ease;
      border-radius: 12px;

      &:hover {
        background: $color-60-black-alt;
      }
    }
  }
}

.two-column-layout {
  display: flex;
  gap: 24px;
  padding: 0 24px 24px 24px;
  height: calc(100vh - 90px);

  .left-column {
    flex: 1;
    background: $main-bg;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba($color-30, 0.08);
    border-top: 1px solid rgba($color-30, 0.08);
    overflow: hidden;
  }

  .right-column {
    min-width: 24vw;
    max-width: 480px;
    background: $main-bg;
    border-radius: 24px;
    height: 100%;
    padding: 32px;
    border-left: 1px solid rgba($color-30, 0.08);
    border-top: 1px solid rgba($color-30, 0.08);

    position: relative;
    align-self: center;
    justify-content: center;

    h2 {
      color: $color-30;
      font-size: 24px;
      font-family: "Lexend", sans-serif;
      font-weight: 500;
    }
  }
}

.messages-container {
  flex: 1;
  padding: 24px 16px;
  padding-bottom: 8px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  overflow-y: scroll;
  background: inherit;
  transition: all 0.66s cubic-bezier(0.25, 0, 0, 1);

  &::-webkit-scrollbar {
    display: none;
  }
}

.ContentAwaiting {
  align-items: center !important;
  justify-content: center !important;
  display: flex;

  background: $color-60-black-alt;
}

.message {
  padding: 12px;
  border-radius: 16px;
  max-width: 80%;

  &.user {
    align-self: flex-end;
    background: $color-60-alt;
    color: $color-30;
    text-align: right;
  }

  &.assistant {
    align-self: flex-start;
    text-align: left;
    background: $color-10;
    color: $color-30;

    strong {
      font-weight: 700;
    }

    i {
      font-style: italic;
      font-weight: 300;
    }
  }

  &.ContentAwaiting {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-60-black-alt;
    min-height: 48px;
    padding: 8px;
  }
}

.input-area {
  position: sticky;
  bottom: 24px;
  padding: 0 32px;
  background: $main-bg;
  z-index: 10;

  .input-container {
    display: flex;
    align-items: center;
    gap: 12px;
    background: $color-60-alt;
    border-radius: 12px;
    padding: 8px;
    border-left: 1px solid rgba($color-30, 0.08);
    border-top: 1px solid rgba($color-30, 0.08);
  }

  .text-input {
    flex: 1;
    background: rgba($color-60-black, 0.38);
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    height: 48px;

    .message-icon {
      margin-left: 12px;
      width: 24px;
      height: 24px;
    }

    input {
      flex: 1;
      background: transparent;
      border: none !important;
      outline: none;
      color: $color-30;
      margin-right: 12px;
      height: 100%;
      &::placeholder {
        color: rgba($color-30, 0.5);
      }
    }
  }

  .media-button {
    padding: 12px 24px;
    background: rgba($color-60-black, 0.38);
    border-radius: 8px;
  }

  .send-button {
    padding: 12px 32px;
    background: $color-10;
    border-radius: 24px;
    transition: all 0.3s ease;

    &:hover {
      background: $color-10-dark;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.transcript {
  color: $color-30;
  font-size: 1rem;
  font-weight: 500;
}
@media screen and (max-width: 1080px) {
  .MbileLayout {
    .header {
      padding: 8px 24px;
    }
    .two-column-layout {
      flex-direction: column-reverse;
      gap: 0;

      .right-column {
        max-width: 100% !important;
        width: 100% !important;
      }
      .left-column {
        height: 0 !important;
        border-color: transparent !important;
        background: transparent !important;
      }
    }
  }
}
